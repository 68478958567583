import { useMemo, useState } from 'react'
import { Trade, TradeType } from '@starex-dex/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon, AutoRenewIcon, ArrowUpDownIcon } from 'packages/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import QuestionHelper from 'components/QuestionHelper'
import styled from 'styled-components'
import { TruncatedText, SwapShowAcceptChanges, StyledBalanceMaxMini } from './styleds'
import FormattedPriceImpact from './FormattedPriceImpact'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 20px;
  background-color: transparent;
  gap: 8px;
  border: 2px solid #9b9b9b;
  padding: 14px;
`

const ModalSwap = styled(AutoColumn)`
  padding: 20px;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  )
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <>
      <ModalSwap gap="md">
        <RowBetween align="flex-end">
          <RowFixed gap="0px">
            <TruncatedText
              fontSize="24px"
              mr="12px"
              // color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? 'pink' : 'black'}
              color="pink"
            >
              {trade.inputAmount.toSignificant(6)}
            </TruncatedText>
          </RowFixed>

          <RowFixed gap="0px">
            <Text color="black" fontSize={['14px', , , , '24px']} mr="10px">
              {trade.inputAmount.currency.symbol}
            </Text>
            {/* <CurrencyLogo currency={trade.inputAmount.currency} size="24px" /> */}
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <ArrowUpDownIcon color="black" width="16px" ml="4px" />
        </RowFixed>
        <RowBetween align="flex-end">
          <RowFixed gap="0px">
            <TruncatedText
              // color={
              //   priceImpactSeverity > 2
              //     ? 'failure'
              //     : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
              //     ? 'pink'
              //     : 'black'
              // }
              color="primary"
            >
              {trade.outputAmount.toSignificant(6)}
            </TruncatedText>
          </RowFixed>

          <RowFixed gap="0px">
            <Text color="black" fontSize={['14px', , , , '24px']} mr="10px">
              {trade.outputAmount.currency.symbol}
            </Text>
            {/* <CurrencyLogo currency={trade.outputAmount.currency} size="24px" /> */}
          </RowFixed>
        </RowBetween>
        {showAcceptChanges ? (
          <SwapShowAcceptChanges justify="flex-start" gap="0px">
            <RowBetween>
              <RowFixed>
                <ErrorIcon mr="8px" />
                <Text color="black" bold fontSize={['12px', , , , '16px']}>
                  {' '}
                  {t('Price Updated')}
                </Text>
              </RowFixed>
              <Button onClick={onAcceptChanges}>
                <Text color="black" bold fontSize={['12px', , , , '16px']}>
                  {t('Accept')}
                </Text>
              </Button>
            </RowBetween>
          </SwapShowAcceptChanges>
        ) : null}

        <SwapModalFooterContainer>
          <RowBetween align="center">
            <Text color="black" bold fontSize={['10px', , , , '14px']}>
              {t('Price')}
            </Text>
            <Text
              color="black"
              fontSize={['10px', , , , '14px']}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'right',
                paddingLeft: '10px',
              }}
            >
              {formatExecutionPrice(trade, showInverted)}
              <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
                <ArrowUpDownIcon style={{ rotate: '90deg' }} color="black" width="14px" />
              </StyledBalanceMaxMini>
            </Text>
          </RowBetween>

          <RowBetween>
            <RowFixed>
              <Text color="black" fontSize={['10px', , , , '14px']} bold>
                {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
              </Text>
              <QuestionHelper
                text={t(
                  'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
                )}
                ml="4px"
              />
            </RowFixed>
            <RowFixed>
              <Text color="black" fontSize={['10px', , , , '14px']} bold>
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                  : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
              </Text>
              <Text color="black" fontSize={['10px', , , , '14px']} ml="4px">
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? trade.outputAmount.currency.symbol
                  : trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <Text color="black" fontSize={['10px', , , , '14px']} bold>
                {t('Price Impact')}
              </Text>
              <QuestionHelper
                text={t('The difference between the market price and your price due to trade size.')}
                ml="4px"
              />
            </RowFixed>
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} color="black" />
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <Text color="black" fontSize={['10px', , , , '14px']} bold>
                {t('Liquidity Provider Fee')}
              </Text>
              <QuestionHelper
                text={
                  <>
                    <Text color="black" mb="12px" fontSize={['12px', , , , '14px']}>
                      {t('For each trade a %amount% fee is paid', { amount: '0.25%' })}
                    </Text>
                    <Text color="black" fontSize={['12px', , , , '14px']}>
                      - {t('%amount% to LP token holders', { amount: '0.17%' })}
                    </Text>
                    <Text color="black" fontSize={['12px', , , , '14px']}>
                      - {t('%amount% to the Treasury', { amount: '0.03%' })}
                    </Text>
                    <Text color="black" fontSize={['12px', , , , '14px']}>
                      - {t('%amount% towards Starex buyback and burn', { amount: '0.05%' })}
                    </Text>
                  </>
                }
                ml="4px"
              />
            </RowFixed>
            <Text color="black" fontSize={['10px', , , , '14px']} bold>
              {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
            </Text>
          </RowBetween>
        </SwapModalFooterContainer>

        {recipient !== null ? (
          <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
            <Text color="textSubtle" fontSize={['12px', , , , '16px']}>
              {recipientSentToText}
              <b title={recipient}>{truncatedRecipient}</b>
              {postSentToText}
            </Text>
          </AutoColumn>
        ) : null}
      </ModalSwap>

      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '20px' }}>
        <Text fontSize={['10px', , , , '14px']} color="black" textAlign="left" style={{ width: '100%' }} lineHeight="2">
          {estimatedText}
          <b>
            {amount} {symbol}
          </b>
          {transactionRevertText}
        </Text>
      </AutoColumn>
    </>
  )
}
