import styled from 'styled-components'
import {
  ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  ChartDisableIcon,
} from 'packages/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex).attrs({ padding: ['10px', , , '16px 25px'] })`
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid black;
`

const StyledHeading = styled(Heading).attrs({ scale: 'md' })`
  font-weight: 500;
  color: black;
`

const StyledText = styled(Text)`
  color: #fff;

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 12px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 16px;
  }
`

const Dots = styled.span`
  width: clamp(16px, 4vw, 26px);
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid black;

  &:first-child {
    background-color: yellow;
  }

  &:nth-child(2) {
    background: green;
  }

  &:nth-child(3) {
    background: #3b9aff;
  }
`

// const ColoredIconButton = styled(IconButton)`
//   color: ${({ theme }) => theme.colors.textScroll};
// `

const CurrencyInputHeader: React.FC<Props> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

  return (
    <CurrencyInputContainer>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" style={{ gap: '5px' }}>
          <Dots />
          <Dots />
          <Dots />
        </Flex>
        <Flex>
          <StyledHeading as="h2">{title}</StyledHeading>
          <StyledText>{subtitle}</StyledText>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" width="88px">
          <Flex>
            <NotificationDot show={expertMode}>
              <GlobalSettings color="black" mr="0" />
            </NotificationDot>
          </Flex>
        </Flex>
      </Flex>
      {/* <Flex>
          {setIsChartDisplayed && (
            <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
              {isChartDisplayed ? <ChartDisableIcon color="#ff6107" /> : <ChartIcon width="24px" color="#ff6107" />}
            </ColoredIconButton>
          )}
          <NotificationDot show={expertMode}>
              <GlobalSettings color="pink" mr="0" />
            </NotificationDot>
          <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon color="#ffffff" width="24px" />
          </IconButton>
          <IconButton variant="text" scale="sm" onClick={() => onRefreshPrice()}>
            <RefreshIcon disabled={!hasAmount} width="27px" />
          </IconButton>
        </Flex> */}
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
