import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 31">
      <mask
        id="mask0_1708_4106"
        style={{ maskType: 'luminance' }}
        width="21"
        height="31"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M0 0h21v31H0V0z" />
      </mask>
      <g mask="url(#mask0_1708_4106)">
        <path
          fill="#000"
          d="M4.742 28.34v.886h-.678v-.885h.678zm1.354 2.658v-.886h.677v-.886h-.677V26.57H5.42v2.656h-.677v.886h.677v.886h.677zm.677-1.772h.678v-.885h-.678v.885zm-3.386-1.77v.885h.677v-.886h-.677zm4.064.885h.677v-.886h-.677v.886zM2.709 26.57v.885h.678v-.885h-.678zm5.419.885h.677v-.885h-.677v.885zm-6.096-1.771v.886h.677v-.886h-.677zm2.71 0v.886h.677v-.886h-.677zm2.031 0h-.677v.886h.677v-.886zm2.032.886h.678v-.886h-.678v.886zm-7.45-1.772v.886h.677v-.886h-.677zm2.71 0v.886h.677v-.886h-.678zm2.708.886h.678v-.886h-.678v.886zm2.71 0h.677v-.886h-.677v.886zM.677 23.913v.885h.678v-.885H.677zm2.71 0v.885h.677v-.885h-.677zm4.064.885h.677v-.885h-.677v.885zm2.71 0h.677v-.885h-.678v.885zm-7.452-1.772v.887h.678v-.887h-.678zm5.419.887h.677v-.887h-.677v.887zM.678 22.14v-.886h.677v-.885H.677v-1.772H0v5.315h.677V22.14zm1.354 0v.885h.677v-.885h-.677zm6.773.885h.678v-.885h-.678v.885zm-7.45-1.77v.885h.677v-.886h-.677zm8.128.885h.677v-.886h-.677v.886zm1.355-1.771h-.678v.885h.678v2.658h.677v-5.315h-.677v1.772zm-8.806-1.772h1.355v-.885H.677v.885h1.355zm6.773 0h2.033v-.885h-2.71v.885h.677zm-4.74-1.77V.885h-.678v16.827h.677v-.886zm9.482 11.513v-.886h3.387v2.657h.676V13.285h2.71v-.886h-2.71V9.742h-.676V26.57h-3.387V9.742h-.678V12.4H10.16v.886h2.709v16.827h.678v-1.771zM20.32 8.857h-2.71v.885h2.71v2.657h.677V7.086h-.677v1.77zm-10.16 1.771v-.886h2.709v-.885H10.16V7.086h-.677v5.313h.677v-1.771zm.678-4.428h-.678v.886h.678V6.2zm9.482.886V6.2h-.678v.886h.678zm-8.806-1.772h-.676V6.2h.676v-.886zm8.128.886v-.886h-.677V6.2h.677zm-7.45-1.772h-.678v.886h.678v-.886zm6.773.886v-.886h-.677v.886h.677zM12.87 3.542h-.677v.886h.677v-.886zm5.419.886v-.886h-.678v.886h.678zm-4.741-1.771h-.678v.885h.678v-.885zm4.063.885v-.885h-.677v.885h.677zM7.45 2.657v15.056h.678V.886h-.677v1.772-.001zm6.774-.886h-.677v.886h.677V1.77zm2.71.886V1.77h-.678v.886h.677zM14.9.886h-.677v.885h.677V.886zm1.355.885V.886h-.677v.885h.677zM5.419.886H7.45V0H4.064v.886H5.42zM15.579 0H14.9v.886h.678V0zm-1.355 30.998h2.71v-.886h-3.387v.886h.677z"
        />
      </g>
    </Svg>
  )
}

export default Icon
