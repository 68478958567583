import { Box, Flex } from 'packages/uikit'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
`

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 0;
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  ${({ theme }) => theme.mediaQueries.xxs} {
    width: 256px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 330px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 665px;
  }
`
